import React, { useState, useEffect, useRef } from "react";
import AdminTopBar from "./AdminTopBar";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../../Services/firebase-config.js";
import { Modal } from "react-modal";
import { Bar } from "react-chartjs-2"; // Assuming you're using Chart.js
import {
  FilterIcon,
  SearchIcon,
  DocumentReportIcon,
} from "@heroicons/react/outline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {
  doc,
  getDocs,
  collection,
  updateDoc,
  onSnapshot,
  limit,
  query,
  where,
} from "firebase/firestore";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";
import { useAuth } from "../../Contexts/AuthContext";

const Submissions = () => {
  const [hoveredSubmissionId, setHoveredSubmissionId] = useState(null);
  const { currentUser } = useAuth();
  const location = useLocation();
  const bureau = location.state?.bureau;
  const { userData, submissions, setSubmissions } = useApplicationForm();

  // Initialize selectedBureau to bureau or user's department
  const [selectedBureau, setSelectedBureau] = useState(
    bureau ?? userData?.department ?? ""
  );

  // const [selectedBureau, setSelectedBureau] = useState(bureau);s
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewed, setReviewed] = useState(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!selectedBureau && userData?.department) {
      setSelectedBureau(userData.department);
    }

    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }

    const unsubscribe = fetchFormData();
    return unsubscribe;
  }, [selectedBureau, reviewed]);

  const fetchFormData = () => {
    const baseQuery = [
      where("submitted", "==", true),
      ...(selectedBureau ? [where("valueChain", "==", selectedBureau)] : []),
    ];
    const q = query(collection(db, "applications"), ...baseQuery);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const submissionsList = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          let averageScore = "Not Reviewed";

          if (data.reviews) {
            const reviews = data.reviews;
            const totalPoints = Object.values(reviews).reduce(
              (sum, review) => sum + review.totalPoints,
              0
            );
            averageScore = (totalPoints / Object.keys(reviews).length).toFixed(
              1
            );
          }

          return {
            id: doc.id,
            ...data,
            averageScore:
              averageScore === "Not Reviewed" ? -1 : parseFloat(averageScore),
          };
        });

        // Sort by averageScore in descending order
        submissionsList.sort((a, b) => b.averageScore - a.averageScore);

        setSubmissions(submissionsList);
      } else {
        setSubmissions([]);
      }
    });

    return unsubscribe;
  };

  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("ascending");
  const navigate = useNavigate(); // To handle navigation

  const bureaus = [
    "Pathogen Economy",
    "Mobility",
    "Infrastructure Innovations",
    "Industry 4.0+",
    "Aeronautics and Space",
    "Productivity Acceleration",
    "STI Support Services",
  ];

  const handleUpdate = async (id, key, value) => {
    await updateDoc(doc(db, "applications", id), {
      [key]: value,
    });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle sorting by innovation title
  const handleSort = () => {
    const sortedSubmissions = [...submissions].sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.title.localeCompare(b.title);
      } else {
        return b.title.localeCompare(a.title);
      }
    });
    setSortDirection(
      sortDirection === "ascending" ? "descending" : "ascending"
    );
    setSubmissions(sortedSubmissions);
  };

  const filteredSubmissions = submissions?.filter((submission) => {
    const reviews = submission.reviews || {};
    const hasCurrentUserReviewed =
      reviews?.[currentUser?.uid]?.reviewed === true;

    // Filter by the selected review status
    if (reviewed === "reviewedByMe" && !hasCurrentUserReviewed) return false; // Only show submissions reviewed by the current user
    if (reviewed === "notReviewedByMe" && hasCurrentUserReviewed) return false; // Only show submissions not reviewed by the current user

    // Continue filtering by general review status
    if (reviewed === true) {
      const isReviewed = Object.values(reviews).some(
        (review) => review.reviewed === true
      );
      if (!isReviewed) return false; // Filter out unreviewed
    }
    if (reviewed === false) {
      const isReviewed = Object.values(reviews).some(
        (review) => review.reviewed === true
      );
      if (isReviewed) return false; // Filter out reviewed
    }

    // Continue filtering by search term if applicable
    if (
      searchTerm &&
      !submission.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
      return false;

    return true; // Return the submission if it passes all filters
  });

  const handleRowClick = (submission) => {
    navigate(`/submissions/${submission.id}`, { state: { submission } });
  };

  const toSentenceCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const openModal = (bureau) => {
    setSelectedBureau(bureau);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const handleGenerateReport = () => {
    const submissionsForReport = filteredSubmissions.map((submission) => {
      // Extract the necessary data for each submission
      const formattedReviews = Object.entries(submission.reviews || {}).map(
        ([reviewId, review]) => ({
          reviewerName: review.reviewName || "Unknown Reviewer",
          comment: review.comments || "No Comments", // 'comments' field exists in the reviews map
        })
      );
  
      return {
        ...submission,
        formattedReviews, // Add formatted reviews for each submission
      };
    });
  
    // Navigate to the report page, passing the relevant data
    navigate("/department-report", {
      state: {
        submissions: submissionsForReport,
        bureauName: selectedBureau,
        reviewers: [
          ...new Set(
            filteredSubmissions.flatMap((sub) =>
              Object.values(sub.reviews || {}).map(
                (review) => review.reviewName || "Unknown Reviewer"
              )
            )
          ),
        ], // Get unique reviewers
      },
    });
  };
  
  


  const downloadCSV = () => {
    const csvRows = [];

    // Initialize headers with fixed columns
    const headers = ["Innovation Title", "Average Score", "Reviewers"];
    let maxComments = 0; // Track the maximum number of comments for dynamic column generation

    // First loop through submissions to determine the max number of comments
    submissions.forEach((submission) => {
      const { reviews } = submission;
      const numComments = Object.values(reviews || {}).length;
      if (numComments > maxComments) {
        maxComments = numComments;
      }
    });

    // Add dynamic comment headers based on the max number of comments
    for (let i = 1; i <= maxComments; i++) {
      headers.push(`Comment ${i}`);
    }

    // Push headers to CSV rows
    csvRows.push(headers.join(","));

    // Helper function to escape quotes and wrap text with commas in quotes
    const escapeCSVValue = (value) => {
      if (typeof value === "string") {
        // Escape double quotes by doubling them
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value;
    };

    // Now process each submission
    submissions.forEach((submission) => {
      const { title, reviews, averageScore } = submission;

      // Handle titles with commas by wrapping them in quotes
      const formattedTitle = escapeCSVValue(title);

      // Calculate the average score (if it exists)
      const formattedAverageScore =
        averageScore !== -1 ? averageScore : "Not Reviewed";

      // Extract reviewer names and comments
      const reviewerNames = Object.values(reviews || {})
        .map((review) => review.reviewName || "Unknown Reviewer")
        .join("; ");
      const comments = Object.values(reviews || {}).map((review) =>
        escapeCSVValue(review.comments || "No Comments")
      );

      // Fill in any missing comments with empty strings to align the number of comment columns
      while (comments.length < maxComments) {
        comments.push(""); // Fill in missing comments with empty strings
      }

      // Build the CSV row with title, average score, reviewer names, and all comments
      const row = [
        formattedTitle,
        formattedAverageScore,
        escapeCSVValue(reviewerNames),
        ...comments,
      ];
      csvRows.push(row.join(","));
    });

    // Create the CSV content
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);

    // Trigger the CSV download
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "submissions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AdminTopBar title="Submissions" />

      <div className="container mx-auto p-6 space-y-6">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          {/* Search Bar on the Left */}
          <div className="flex items-center border border-gray-300 p-2 transition-all focus-within:ring-2 focus-within:ring-blue-500">
            <SearchIcon className="w-5 h-5 text-gray-500 mr-2" />
            <input
              type="text"
              className="w-40 md:w-64 transition-all focus:outline-none"
              placeholder="Search submissions?..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {/* Right-aligned Filters and Button */}
          <div className="flex items-center ml-auto space-x-4">
            {/* Filter by All, Reviewed, Not Reviewed */}
            <select
              className="border border-gray-300 p-2"
              value={reviewed ?? ""}
              onChange={(e) =>
                setReviewed(
                  e.target.value === "true"
                    ? true
                    : e.target.value === "false"
                    ? false
                    : e.target.value === "reviewedByMe"
                    ? "reviewedByMe"
                    : e.target.value === "notReviewedByMe"
                    ? "notReviewedByMe"
                    : null
                )
              }
            >
              <option value="">All Submissions</option>
              <option value="true">Reviewed</option>
              <option value="false">Not Reviewed</option>
              <option value="reviewedByMe">Reviewed by Me</option>
              <option value="notReviewedByMe">Not Reviewed by Me</option>
            </select>

            {/* Bureau Filter */}
            {userData?.role === "Administrator" && (
              <select
                className="border border-gray-300 p-2 focus:ring-2 focus:ring-blue-500"
                value={selectedBureau ?? ""}
                // disabled={userData?.role !== "Administator"}
                onChange={(e) => setSelectedBureau(e.target.value)}
              >
                <option value="">Filter by Bureau</option>
                {bureaus.map((bureau, index) => (
                  <option key={index} value={bureau}>
                    {bureau}
                  </option>
                ))}
              </select>
            )}

            {/* Report Button with Icon */}
            {/* Conditionally render the Report button if the user is either an Administrator or a Team Lead */}
            {(userData.role === "Administrator" ||
              userData.position === "Team Lead") && (
              <button
                className="flex items-center bg-custom-blue-1 text-white p-2 hover:bg-blue-600 transition duration-300 ease-in-out"
                onClick={handleGenerateReport}
                disabled={!selectedBureau} // Disable button if no bureau is selected
              >
                <DocumentReportIcon className="w-5 h-5 mr-2" />
                <span>Generate Report</span>
              </button>
            )}
          </div>
        </div>

        <table className="min-w-full bg-white border border-gray-300 table-auto">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">No</th>
              <th
                className="py-2 px-4 border-b text-left cursor-pointer"
                onClick={handleSort}
              >
                Innovation Title {sortDirection === "ascending" ? "↑" : "↓"}
              </th>
              <th className="py-2 px-4 border-b text-left">Reviewers</th>
              <th className="py-2 px-4 border-b text-left">Score</th>
              {userData?.role === "Administrator" && (
                <th className="py-2 px-4 border-b text-left">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredSubmissions?.map((submission, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(submission)}
                onMouseEnter={() => setHoveredSubmissionId(submission.id)}
                onMouseLeave={() => setHoveredSubmissionId(null)}
                className={`cursor-pointer hover:bg-gray-100 ${
                  submission.reviews && submission.reviews[currentUser.uid]
                    ? "border-l-4 border-green-200"
                    : ""
                }`}
              >
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{submission.title}</td>

                <td className="py-2 px-4 border-b relative">
                  {Object.keys(submission.reviews ?? {}).length}

                  {/* Show reviewer details on hover */}
                  {hoveredSubmissionId === submission.id && (
                    <div className="absolute bg-white border border-gray-300 shadow-lg p-2  left-0 top-full mt-2 w-64 z-10">
                      <h3 className="font-bold mb-1">Reviewers:</h3>
                      <ul className="text-sm text-gray-700">
                        {Object.keys(submission.reviews ?? {}).map(
                          (reviewId) => {
                            const review = submission.reviews?.[reviewId] || {}; // Fallback to an empty object if review is null
                            const isCurrentUser = reviewId === currentUser?.uid;
                            return (
                              <li
                                key={reviewId}
                                className={`p-2  ${
                                  isCurrentUser
                                    ? "bg-blue-100 font-semibold"
                                    : ""
                                }`}
                              >
                                {/* Safely display reviewer's name or fallback */}
                                {review?.reviewName || "Unknown Reviewer"}
                                {/* Safely display score only if the current user is the reviewer and score exists */}
                                {isCurrentUser &&
                                  review?.totalPoints !== undefined && (
                                    <span className="ml-2 text-blue-600">
                                      (Your score: {review.totalPoints})
                                    </span>
                                  )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </td>

                <td className="py-2 px-4 border-b">
                  {submission.averageScore !== -1 ? (
                    submission.averageScore
                  ) : (
                    <span className="text-red-600">Not Reviewed</span>
                  )}
                </td>

                {userData?.role === "Administrator" && (
                  <td className="py-2 px-4 border-b">
                    <select
                      className="border border-gray-300 p-2 "
                      value={submission.valueChain ?? ""}
                      onChange={(e) =>
                        handleUpdate(
                          submission.id,
                          "valueChain",
                          e.target.value
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="" disabled>
                        Forward to Bureau
                      </option>
                      {bureaus.map((bureau, i) => (
                        <option key={i} value={bureau}>
                          {bureau}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Submissions;
