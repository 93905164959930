import React, { useState, useEffect } from "react";
import AdminTopBar from "./AdminTopBar";
import { doc, collection, updateDoc, onSnapshot, limit, query, where, orderBy } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import { SearchIcon } from "@heroicons/react/outline";
import { getAuth } from "firebase/auth";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [roleFilter, setRoleFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");


    // Get the authenticated user's email
    const auth = getAuth();


  useEffect(() => {
    const unsubscribe = fetchFormData(sortConfig, searchTerm);
    return unsubscribe;
  }, [sortConfig, searchTerm, roleFilter, departmentFilter]);

  const fetchFormData = (sortConfig, searchTerm) => {
    let q = query(collection(db, "users"), 
    
    limit(50)
  
  );

    if (searchTerm) {
      q = query(q, where("displayName", ">=", searchTerm), where("displayName", "<=", searchTerm + "\uf8ff"));
    }

    if (roleFilter) {
      q = query(q, where("role", "==", roleFilter));
    }

    if (departmentFilter) {
      q = query(q, where("department", "==", departmentFilter));
    }

    if (sortConfig.key) {
      q = query(q, orderBy(sortConfig.key, sortConfig.direction));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } else {
        setUsers([]);
      }
    });

    return unsubscribe;
  };

  const handleUpdate = async (id, key, value) => {
    await updateDoc(doc(db, "users", id), {
      [key]: value,
    });
  };

  const departments = [
    "N/A",
    "Pathogen Economy",
    "Mobility",
    "Infrastructure Innovations",
    "Industry 4.0+",
    "Aeronautics and Space",
    "Productivity Acceleration",
    "STI Support Services",
  ];

  const roles = ["Applicant", "Reviewer", "Administrator"];
  // Function to sort the table based on the selected column
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      <AdminTopBar title="User Management" />

      <div className="container mx-auto p-6">

        {/* Filters and Search Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          {/* Filter by Role */}
          <select
            className="border border-gray-300 p-2 mr-4"
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
          >
            <option value="">Filter by Role</option>
            {roles.map((role, i) => (
              <option key={i} value={role}>
                {role}
              </option>
            ))}
          </select>

          {/* Filter by Department */}
          <select
            className="border border-gray-300 p-2"
            value={departmentFilter}
            onChange={(e) => setDepartmentFilter(e.target.value)}
          >
            <option value="">Filter by Department</option>
            {departments.map((dept, i) => (
              <option key={i} value={dept}>
                {dept}
              </option>
            ))}
          </select>

          {/* Expandable Search Bar with Icon */}
          <div className="flex items-center border border-gray-300 p-2  transition-all focus-within:ring-2 focus-within:ring-blue-500 ml-auto">
            <SearchIcon className="w-5 h-5 text-gray-500 mr-2" />
            <input
              type="text"
              className="w-40 md:w-64 transition-all focus:outline-none"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.trim())}
            />
          </div>
        </div>

        {/* Users Table */}
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">No</th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("displayName")}
              >
                Name{" "}
                {sortConfig.key === "displayName" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("role")}
              >
                Role{" "}
                {sortConfig.key === "role" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="py-2 px-4 border-b cursor-pointer"
                onClick={() => handleSort("department")}
              >
                Department (Bureau){" "}
                {sortConfig.key === "department" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{user.displayName}</td>

                {/* Editable Role field */}
                <td className="py-2 px-4 border-b">
                  <select
                    className="border border-gray-300 p-2 "
                    value={user.role }
                    onChange={(e) =>
                      handleUpdate(user.id, "role", e.target.value)
                    }
                  >
                    <option value="Applicant">Applicant</option>
                    <option value="Reviewer">Reviewer</option>
                    <option value="Administrator">Administrator</option>
                  </select>
                </td>

                {/* Editable Department field */}
                <td className="py-2 px-4 border-b">
                  <select
                    className="border border-gray-300 p-2 "
                    value={user.department}
                    onChange={(e) =>
                      handleUpdate(user.id, "department", e.target.value)
                    }
                  >
                    {departments.map((dept, i) => (
                      <option key={i} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Users;
