// import React, { useState, useEffect } from "react";
// import AdminTopBar from "./AdminTopBar";
// import { doc, addDoc, collection } from "firebase/firestore";
// import { db, storage } from "../../Services/firebase-config.js";
// import { useAuth } from "../../Contexts/AuthContext";
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import Modal from "react-modal";
// import { v4 as uuidv4 } from 'uuid';

// Modal.setAppElement("#root");

// const ApplicationForm = () => {
//   const { currentUser } = useAuth();
//   const [formData, setFormData] = useState({});
//   const [loadingFormData, setLoadingFormData] = useState(false);
//   const [error, setError] = useState(null);
//   const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // Confirmation modal
//   const [applicationId, setApplicationId] = useState(null);

//   useEffect(() => {
//     // Generate a new application ID when the component mounts
//     const newApplicationId = uuidv4();
//     setApplicationId(newApplicationId);
//     console.log("Generated application ID:", newApplicationId);
//   }, []);
  
  
//   // Handle input changes for text fields
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handle file uploads
//   const handleFileChange = async (e) => {
//     setLoadingFormData(true);
//     const { name, files } = e.target;
//     const fileExtension = files[0].name.split(".").pop();
//     const filename = `${name}.${fileExtension}`;
    
//     try {
//       // Upload file to the folder named after the applicationId
//       const fileURL = await uploadFileAndGetURL(files, filename, applicationId);
//       setFormData({ ...formData, [name]: fileURL });
//     } catch (error) {
//       console.error("Error uploading file: ", error);
//     }

//     setLoadingFormData(false);
//   };


//   // Modify the function to use the application ID with "physical"
//   async function uploadFileAndGetURL(files, filename, appId) {
//     try {
//       // Create a reference to the folder named after the application ID
//       const storageRef = ref(storage, `applications/${appId}/${filename}`);
//       await uploadBytes(storageRef, files[0]);
//       return await getDownloadURL(storageRef);
//     } catch (error) {
//       console.error("Error uploading file or getting download URL", error);
//       throw error;
//     }
//   }

//    // Handle form submission (create new application)
//    const handleSubmit = async (e, submitted = false) => {
//     if (e) {
//       e.preventDefault(); // Only call preventDefault if `e` exists
//     }
//     setError(null);
//     setLoadingFormData(true);

//     try {
//       // Create a new application document with a unique ID and set the "submitted" field
//       const applicationRef = await addDoc(collection(db, "applications"), {
//         ...formData,
//         submitted: submitted, // Set submitted to true when form is fully submitted
//         isPhysical: true, // This is true for all new applications
//         submittedBy: currentUser.uid, // User ID
//         submittedAt: new Date().toISOString(), // Timestamp
//       });
//       // Clear the form and generate a new application ID for the next submission
//       setFormData({});
//       const newApplicationId = uuidv4();
//       setApplicationId(newApplicationId);
//       console.log("New Application ID for next submission:", newApplicationId);

//     } catch (error) {
//       console.error("Error submitting new application: ", error);
//       setError("Failed to submit application. Please try again.");
//     }

//     setLoadingFormData(false);
//   };
//   // When the user clicks the submit button, ensure all fields are filled and mark the submission as complete
//   const submitApplication = () => {
//     setError(null);
//     setIsConfirmModalOpen(true); // Show confirmation modal
//   };

//   // Handle the confirmation submission
//   const handleConfirmSubmit = async () => {
//     setIsConfirmModalOpen(false);
//     await handleSubmit(null, true); // Submit the form with 'submitted' set to true
//   };

//   if (loadingFormData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <AdminTopBar title="Application Form" />
//       <p>Application ID: {applicationId}</p>
//       <div className="w-full mx-auto relative">
//         {loadingFormData && (
//           <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
//             <div className="flex justify-center items-center h-24">
//               <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
//             </div>
//           </div>
//         )}
//         <form className="p-8 max-w-3xl mx-auto" onSubmit={handleSubmit}>
//           {/* Title of Venture */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">1. TITLE OF YOUR VENTURE:</label>
//             <input
//               type="text"
//               name="title"
//               className="w-full border border-gray-300 p-2"
//               value={formData?.title ?? ""}
//               onChange={handleInputChange}
//             />
//           </div>

//           {/* Description */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">2. BRIEF DESCRIPTION:</label>
//             <textarea
//               name="description"
//               rows="4"
//               className="w-full border border-gray-300 p-2"
//               value={formData?.description ?? ""}
//               onChange={handleInputChange}
//             />
//           </div>

//           {/* Value Chain Selection */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">3. INDUSTRIAL VALUE CHAIN:</label>
//             <select
//               name="valueChain"
//               className="w-full border border-gray-300 p-2"
//               value={formData?.valueChain ?? ""}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Value Chain</option>
//               {[
//                 "Pathogen Economy",
//                 "Mobility",
//                 "Infrastructure Innovations",
//                 "Industry 4.0+",
//                 "Aeronautics and Space",
//                 "Productivity Acceleration",
//                 "STI Support Services",
//               ].map((chain, index) => (
//                 <option key={index} value={chain}>
//                   {chain}
//                 </option>
//               ))}
//             </select>
//           </div>

//           {/* Upload Application File */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">4. UPLOAD YOUR APPLICATION FILE:</label>
//             {formData?.applicationFile && (
//               <a href={formData.applicationFile} target="_blank" rel="noopener noreferrer">
//                 Download Current Application
//               </a>
//             )}
//             <input type="file" name="applicationFile" onChange={handleFileChange} />
//           </div>

//           {/* Upload Budget File */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">5. UPLOAD YOUR BUDGET FILE:</label>
//             {formData?.budgetFile && (
//               <a href={formData.budgetFile} target="_blank" rel="noopener noreferrer">
//                 Download Current Budget
//               </a>
//             )}
//             <input type="file" name="budgetFile" onChange={handleFileChange} />
//           </div>

//           {/* Upload Team CVs */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">6. UPLOAD TEAM CVS:</label>
//             {formData?.teamCVs && (
//               <a href={formData.teamCVs} target="_blank" rel="noopener noreferrer">
//                 Download Current CVs
//               </a>
//             )}
//             <input type="file" name="teamCVs" onChange={handleFileChange} />
//           </div>

//           {/* Upload National ID */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">7. UPLOAD NATIONAL ID:</label>
//             {formData?.nationalID && (
//               <a href={formData.nationalID} target="_blank" rel="noopener noreferrer">
//                 Download National ID
//               </a>
//             )}
//             <input type="file" name="nationalID" onChange={handleFileChange} />
//           </div>

//           {/* Upload Support Materials */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">8. UPLOAD SUPPORT MATERIALS (Images/Videos):</label>
//             {formData?.supportMaterials && (
//               <a href={formData.supportMaterials} target="_blank" rel="noopener noreferrer">
//                 Download Support Materials
//               </a>
//             )}
//             <input type="file" name="supportMaterials" onChange={handleFileChange} />
//           </div>

//           {/* Upload Registration Certificate */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">9. UPLOAD REGISTRATION CERTIFICATE:</label>
//             {formData?.registrationCertificate && (
//               <a href={formData.registrationCertificate} target="_blank" rel="noopener noreferrer">
//                 Download Registration Certificate
//               </a>
//             )}
//             <input type="file" name="registrationCertificate" onChange={handleFileChange} />
//           </div>

//           {/* Upload Resolution Letter */}
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2">10. UPLOAD RESOLUTION LETTER:</label>
//             {formData?.resolutionLetter && (
//               <a href={formData.resolutionLetter} target="_blank" rel="noopener noreferrer">
//                 Download Resolution Letter
//               </a>
//             )}
//             <input type="file" name="resolutionLetter" onChange={handleFileChange} />
//           </div>

//           {/* Submit Button */}
//           <div className="mt-6">
//             <button type="button" onClick={submitApplication} className="bg-green-500 text-white px-4 py-2">
//               Submit New Application
//             </button>
//           </div>

//           {error && <div className="text-red-500 mt-4">{error}</div>}
//         </form>
//       </div>

//       {/* Confirm Submission Modal */}
//       <Modal
//         isOpen={isConfirmModalOpen}
//         onRequestClose={() => setIsConfirmModalOpen(false)}
//         className="fixed inset-0 flex items-center justify-center z-50"
//         overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//       >
//         <div className="bg-white p-6 shadow-lg max-w-sm mx-auto text-center">
//           <h2 className="text-xl font-bold mb-4">Confirm Submission</h2>
//           <p className="text-gray-600 mb-6">Are you sure you want to submit your application?</p>
//           <div className="flex justify-center space-x-4">
//             <button onClick={() => setIsConfirmModalOpen(false)} className="bg-gray-200 text-gray-700 px-4 py-2 hover:bg-gray-300">
//               Cancel
//             </button>
//             <button onClick={handleConfirmSubmit} className="bg-green-500 text-white px-4 py-2 hover:bg-green-600">
//               Confirm
//             </button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default ApplicationForm;














import React, { useState, useEffect } from "react";
import AdminTopBar from "./AdminTopBar";
import { useParams } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { db, storage } from "../../Services/firebase-config.js";
import { useAuth } from "../../Contexts/AuthContext";
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import Modal from "react-modal"; // Import modal component
import {
  FaFileWord,
  FaFileExcel,
  FaTrash,
  FaFileImage,
  FaFile,
  FaFilePdf,
} from "react-icons/fa";

Modal.setAppElement("#root");

const ApplicationForm = () => {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const { formData, setFormData } = useApplicationForm();
  const [updatedFormData, setUpdatedFormData] = useState(null);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // For confirm modal
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // For success modal
  const deadline = new Date("2024-09-18T23:59:00"); // Exact deadline: 18th September 2024, 11:59 PM EAT

  const userRef = doc(db, "users", currentUser.uid);
  const docRef = doc(db, "applications", currentUser.uid);
  const [error, setError] = useState(null);

  const fetchFormData = () => {
    // setLoadingFormData(true);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setFormData(docSnap.data());
      } else {
      }
      // setLoadingFormData(false);
    });
    return () => unsubscribe();
  };

  useEffect(() => {
    const unsubscribe = fetchFormData();
    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setUpdatedFormData({ ...updatedFormData, [name]: value });
  };

  const handleFileChange = async (e) => {
    setLoadingFormData(true);
    const { name, files } = e.target;
    const fileExtension = files[0].name.split(".").pop();
    const filename = `${name}.${fileExtension}`;

    const fileURL = await uploadFileAndGetURL(storage, files, filename);

    setFormData({ ...formData, [name]: fileURL });
    setUpdatedFormData({ ...updatedFormData, [name]: fileURL });
    setLoadingFormData(false);
  };

  const handleFileDelete = (name) => {
    setFormData({ ...formData, [name]: null });
    setUpdatedFormData({ ...updatedFormData, [name]: null });
  };

  const submitApplication = () => {
    setError(null);
    if (
      !formData?.title ||
      !formData?.description ||
      !formData?.valueChain ||
      !formData?.applicationFile ||
      !formData?.budgetFile ||
      !formData?.nationalID ||
      !formData?.teamCVs ||
      // !formData?.registrationCertificate ||
      !formData?.resolutionLetter
    ) {
      setError("Please fill all required fields before submitting.");
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setIsConfirmModalOpen(false);
    await handleSubmit(null, true);
    setIsSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  async function uploadFileAndGetURL(storage, files, filename) {
    try {
      const storageRef = ref(
        storage,
        `applications/${currentUser.uid}/${filename}`
      );
      const snapshot = await uploadBytes(storageRef, files[0]);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading file or getting download URL", error);
    }
  }

  const handleSubmit = async (e, submitted = false) => {
    setError(null);
    e?.preventDefault();
    setLoadingFormData(true);

    try {
      await setDoc(
        docRef,
        { ...updatedFormData, submitted: submitted },
        { merge: true }
      );
      await setDoc(
        userRef,
        { applicationSubmitted: submitted },
        { merge: true }
      );
      await sendSubmittedNotification(submitted);
      setUpdatedFormData(null);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setLoadingFormData(false);
  };

  const sendSubmittedNotification = async (submitted) => {
    if (!submitted) return;

    const notificationsRef = collection(db, "notifications");
    await addDoc(notificationsRef, {
      message: "Your application has been submitted successfully.",
      read: false,
      timestamp: new Date(),
      userId: currentUser.uid,
    });
  };

  const UploadedFile = ({ fileURL, filename, onDelete }) => {
    let fileIcon = <FaFile className="w-8 h-8 text-grey-200" />;

    const fileTypes = [
      {
        extensions: ["doc", "docx"],
        icon: <FaFileWord className="w-8 h-8 text-blue-600" />,
      },
      {
        extensions: ["xls", "xlsx"],
        icon: <FaFileExcel className="w-8 h-8 text-green-600" />,
      },
      {
        extensions: ["jpg, jpeg, png"],
        icon: <FaFileImage className="w-8 h-8 text-orange-600" />,
      },
      {
        extensions: ["pdf"],
        icon: <FaFilePdf className="w-8 h-8 text-red-600" />,
      },
    ];

    fileTypes.forEach((fileType) => {
      fileType.extensions.forEach((extension) => {
        if (
          fileURL
            .toLocaleUpperCase()
            .includes(`.${extension.toLocaleUpperCase()}`)
        ) {
          fileIcon = fileType.icon;
        }
      });
    });

    return (
      <a
        href={fileURL}
        target="_blank"
        download
        className="p-2 gap-3 flex items-center w-full border rounded-t hover:bg-gray-100"
      >
        {fileIcon}
        <span className="mr-auto text-gray-500">{filename}</span>
        <button
          type="button"
          onClick={onDelete}
          className="text-red-500 hover:text-red-700"
        >
          {!formData?.submitted && <FaTrash className="w-5 h-5 text-red-600" />}
        </button>
      </a>
    );
  };

  if (new Date() > deadline) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <div className="bg-white p-8 shadow-lg border border-gray-300 text-center max-w-md">
          <FaRegTimesCircle className="text-red-600 w-16 h-16 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-red-600 mb-4">
            We are no longer taking Applications for this Call.
          </h2>
          <p className="text-gray-700 mb-4">
            Please wait for feedback on your submission. If you haven't submitted,
            stay tuned for the next call for applications.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <AdminTopBar title="Application Form" />
      <div className="w-full mx-auto relative">
        {loadingFormData && (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
            <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
              <div className="flex justify-center items-center h-24">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
              </div>
            </div>
          </div>
        )}
        <form className="p-8 max-w-3xl mx-auto" onSubmit={handleSubmit}>
          {/* Title of Venture */}
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="title"
            >
              1. TITLE OF YOUR VENTURE:
              <span className="text-red-500 font-bold"> *</span>
            </label>

            <input
              type="text"
              id="title"
              name="title"
              required
              disabled={formData?.submitted}
              className="w-full border border-gray-300 p-2 "
              value={formData?.title ?? ''}
              onChange={handleInputChange}
            />
          </div>

          {/* Brief Description */}
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              2. BRIEF DESCRIPTION OF THE INNOVATION (500 Words):
              <span className="text-red-500 font-bold"> *</span>
            </label>
            <textarea
              id="description"
              name="description"
              rows="4"
              disabled={formData?.submitted}
              className="w-full border border-gray-300 p-2 "
              value={formData?.description ?? ''}
              onChange={handleInputChange}
            />
            <p className="text-sm text-gray-500 mt-2">
              {500 - formData?.description?.split(" ")?.filter(Boolean)?.length}{" "}
              words remaining
            </p>
          </div>

          {/* Industrial Value Chain */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              3. SELECT THE INDUSTRIAL VALUE CHAIN WHERE YOUR INNOVATION LIES:
              <span className="text-red-500 font-bold"> *</span>
            </label>
            <div className="flex flex-col space-y-2">
              {[
                "Pathogen Economy",
                "Mobility",
                "Infrastructure Innovations",
                "Industry 4.0+",
                "Aeronautics and Space",
                "Productivity Acceleration",
                "STI Support Services",
              ].map((option) => (
                <label key={option} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="valueChain"
                    value={option}
                    disabled={formData?.submitted}
                    checked={formData?.valueChain === option}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="ml-2">{option}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Upload Application File */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              4. UPLOAD YOUR FILLED APPLICATION (WORD DOCUMENT ):
              <span className="text-red-500 font-bold"> *</span>
            </label>
            {formData?.applicationFile && (
              <UploadedFile
                fileURL={formData?.applicationFile}
                filename="Application"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="applicationFile"
                accept=".doc,.docx" // Allow only Word files
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>

          {/* Upload Budget File */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              5. UPLOAD YOUR BUDGET (EXCEL):
              <span className="text-red-500 font-bold"> *</span>
            </label>
            {formData?.budgetFile && (
              <UploadedFile
                fileURL={formData?.budgetFile}
                filename="Budget"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="budgetFile"
                accept=".xls,.xlsx" // Allow only Excel files
                disabled={formData?.submitted}
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>

          {/* Link to Support Materials */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              6. LINK TO SUPPORT MATERIALS (IMAGE, VIDEOS):
            </label>
            <input
              type="url"
              name="supportLink"
              disabled={formData?.submitted}
              value={formData?.supportLink ?? ''}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-2 "
            />
          </div>

          {/* Upload CVs of Team Members */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              7. CVS OF KEY MEMBERS OF THE TEAM (COMPILED PDF):
              <span className="text-red-500 font-bold"> *</span>
            </label>
            {formData?.teamCVs && (
              <UploadedFile
                fileURL={formData?.teamCVs}
                filename="CVs"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="teamCVs"
                accept=".pdf" // Restrict file browser to PDF files
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>
          {/* Upload National ID of the lead Team Members */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              8. COPY OF NATIONAL ID OF VENTURE LEADER:
              <span className="text-red-500 font-bold"> *</span>
            </label>
            {formData?.nationalID && (
              <UploadedFile
                fileURL={formData?.nationalID}
                filename="National ID"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="nationalID"
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>

          {/* Upload Registration Certificate */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              9. REGISTRATION CERTIFICATE (COMPULSORY FOR NON GOVERNMENT
              ORGANIZATION):
            </label>
            {formData?.registrationCertificate && (
              <UploadedFile
                fileURL={formData?.registrationCertificate}
                filename="Registration Certificate"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="registrationCertificate"
                accept=".pdf" // Restrict file browser to PDF files
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>

          {/* Upload Registered Resolution */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              10. LETTER OF SUPPORT FROM HOST ORGANISATION(REGISTERED RESOLUTION, OR REFERENCE LETTER FOR INDIVIDUAL APPLICANTS )(PDF):
              <span className="text-red-500 font-bold"> *</span>
            </label>
            {formData?.resolutionLetter && (
              <UploadedFile
                fileURL={formData?.resolutionLetter}
                filename="Resolution Letter"
                onDelete={handleFileDelete}
              />
            )}
            {!formData?.submitted && (
              <input
                type="file"
                name="resolutionLetter"
                accept=".pdf" // Restrict file browser to PDF files
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-2"
              />
            )}
          </div>

          {error && (
            <div className="mb-4 p-4 bg-red-100 text-red-800 ">{error}</div>
          )}

          {formData?.submitted && (
            <div className="mb-4 p-4 bg-green-100 text-green-800 ">
              Your application has been submitted. You can no longer make any
              changes.
            </div>
          )}

          {/* Action Buttons */}
          {!formData?.submitted && (
            <div className="flex justify-between mt-8 mb-16">
              <button
                onClick={handleSubmit}
                type="button"
                disabled={loadingFormData || !updatedFormData}
                className="bg-gray-400 text-white font-bold py-2 px-4  hover:bg-gray-500"
              >
                SAVE DRAFT
              </button>
              <button
                type="button"
                onClick={submitApplication}
                disabled={loadingFormData}
                className="bg-custom-green-1 text-white font-bold py-2 px-4 hover:bg-green-600"
              >
                SUBMIT APPLICATION
              </button>
            </div>
          )}
        </form>
      </div>
      {/* Confirm Submission Modal */}
      <Modal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6  shadow-lg max-w-sm mx-auto text-center">
          <h2 className="text-xl font-bold mb-4">Confirm Submission</h2>
          <p className="text-gray-600 mb-6">
            Are you sure you want to submit your application?
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setIsConfirmModalOpen(false)}
              className="bg-gray-200 text-gray-700 px-4 py-2  hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmSubmit}
              className="bg-custom-green-1 text-white px-4 py-2  hover:bg-green-600"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>

      {/* Success Modal */}
      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={closeSuccessModal}
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6  shadow-lg max-w-sm mx-auto text-center">
          <h2 className="text-xl font-bold mb-4">Success!</h2>
          <p className="text-gray-600 mb-6">
            Your form has been submitted successfully.
          </p>
          <div className="flex justify-center">
            <svg
              className="w-12 h-12 text-green-500 mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <button
            onClick={closeSuccessModal}
            className="bg-custom-green-1 text-white px-4 py-2  hover:bg-green-600"
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationForm;
